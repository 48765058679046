.overlay-text {
  position: absolute;
  top: 25%;
  left: 59%;
  color: white; /* Change to your preferred text color */
  font-size: 3rem; /* Adjust the size as needed */
  font-weight: bolder;
  text-align: center;
  text-shadow: 
  1px 1px 20px #000, 
  -1px -1px 0 #000,  
  1px -1px 0 #000,  
  -1px 1px 0 #000; 
  
}
.services-button {
  position: absolute;
  bottom: -80%; /* Distance from the bottom */
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  font-size: 1.3rem;
  cursor: pointer;
  color: #004c9e;
  background-color: white; /* Bootstrap secondary color */
  border: 1px solid white; /* Same color as the background */
  border-radius: 0.5rem; /* Rounded corners */
  cursor: pointer;
  /* text-transform: uppercase; */
  text-decoration: none;
  transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transition */
  text-shadow: none;
 }
 .services-button:hover {
  background-color: #004c9e; /* Darker shade on hover */
  border-color: #004c9e; /* Darker border color on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect on hover */
  color: white;
}

.centered {
	text-align: center;
}
.logo-ribbon {
  overflow: hidden;
  width: 100%; /* Ensure it takes the full width */
  background-color: transparent;
  padding: 20px 0;
  margin-bottom: 2.5em;
}

.logo-track {
  display: flex;
  width: calc(200%); /* Twice the width for seamless scrolling */
  animation: scroll 20s linear infinite; /* Adjust time to control speed */
}

.logo-container {
  flex: 0 0 auto; /* Prevents shrinking of items */
  margin: 0 15px;
}

.logo-image {
  height: 50px; /* Adjust as needed */
  object-fit: contain;
  transition: filter 0.3s ease;
}

.logo-image:hover {
  filter: grayscale(0%); /* Optional: Brings color back on hover */
}

@keyframes scroll {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(-50%);
  }
}





body {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif !important;; }


a {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease; }
  a, a:hover {
    text-decoration: none !important; }

.content {
  height: 70vh; }

.footer-20192 {
  position: relative;
  color: #fff;
  padding: 2rem 0;
  background-color: #141d2a; }
  .footer-20192 .container {
    position: relative; }
  .footer-20192 h3 {
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 0;
    line-height: 1.5; }
  .footer-20192 .links li {
    margin-bottom: 10px;
    line-height: 1.5;
    display: block; }
    .footer-20192 .links li a {
      color: #666873; }
      .footer-20192 .links li a:hover {
        color: #fff; }
  .footer-20192 .social li {
    display: inline-block;
    position: relative; }
    .footer-20192 .social li a {
      position: relative;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: inline-block;
      margin: 0;
      padding: 0;
      background-color: #8186d5;
      color: #fff; }
      .footer-20192 .social li a > span {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
  .footer-20192 .footer-logo {
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: .1rem; }
  .footer-20192 .copyright {
    color: #666873; }
  .footer-20192 .cta {
    -webkit-box-shadow: -20px -20px 0 0 rgba(52, 58, 64, 0.2);
    box-shadow: -20px -20px 0 0 rgba(52, 58, 64, 0.2);
    padding: 20px;
    background-color: #8186d5;
    top: -150px;
    position: relative; }
    .footer-20192 .cta h2, .footer-20192 .cta h3 {
      line-height: 1.5; }
    .footer-20192 .cta h3 {
      font-size: 20px; }
.brand a {
  color: #fff;
}


.contact-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 1em;
  background: #f9f9f9;
  border-radius: 5px;
}

.contact-form h2 {
  text-align: center;
  margin-bottom: 1em;
}

.contact-form label {
  margin-bottom: 0.5em;
  color: #333333;
  display: block;
}

.contact-form input,
.contact-form textarea {
  border: 1px solid #CCCCCC;
  padding: 0.5em;
  font-size: 1em;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 1em;
  border-radius: 3px;
}

.contact-form button {
  padding: 0.7em;
  color: #fff;
  background-color: #007BFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.contact-form button:hover {
  background-color: #0056b3;
}


.header{
  padding-top: 2em;
color:  #767678;
  font-size: 2.5em;
}
.small-line {
  width: 50px; /* Adjust width as needed */
  height: 2px; /* Adjust height (thickness) */
  background-color: #000; /* Adjust color as needed */
  margin-bottom: 3cm;
  margin-top: 0;
}
.service-item{
  padding: 1rem !important;
}
.Large{
  font-size: 1.2em;
  font-weight: 500;
}
/* section{
  padding-bottom: 3cm;

} */

.bottom-space{
  padding-bottom: 3cm;
}

.fa {
  color:  #767678;

}
.fa:hover {
  color: #004c9e;
  
}



.position-relative {
  position: relative;
  width: 100%;
  height: auto;
}

.icon-container {
  position: absolute;
  bottom: 24%; /* Adjust vertical positioning */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 15px; /* Space between icons */
}

.icon {
  background-color: white; /* Background color for the circular icons */
  border-radius: 50%;
  padding: 30px; /* Adjust the size of the circle */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px; /* Circle width */
  height: 50px; /* Circle height */
  color: #004c9e; /* Default icon color */
  transition: background-color 0.3s, color 0.3s;
}

.icon:hover {
  background-color: #004c9e; /* Background color on hover */
  color: white; /* Icon color on hover */
}

.icon i {
  font-size: 30px; /* Adjust icon size */
}
#contact .btn:hover, #contact .btn:focus {
	background-color: #004c9e;
	outline: 0px;
}

.section-about-us {
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 20px;
  color: #555;
}
.about-us-image img {
  width: 95%;
  height: auto;
  border-radius: 8px;
}


.faq-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.faq-list {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
}

.faq-item {
  border-bottom: 1px solid #ddd;
}

.faq-item:last-child {
  border-bottom: none;
}

.faq-question {
  padding: 15px;
  cursor: pointer;
  background-color: #f7f7f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-question:hover {
  background-color: #e9e9e9;
}

.faq-answer {
  padding: 15px;
  background-color: #ffffff;
}

.faq-arrow {
  transition: transform 0.3s ease;
}

.faq-arrow.active {
  transform: rotate(180deg);
}


/* Responsive Styles */
@media (max-width: 768px) {
  .overlay-text {
    font-size: 2rem; /* Adjust font size for smaller screens */
    left: 50%; /* Center text horizontally */
    transform: translateX(-50%);
  }
  
  .services-button {
    font-size: 1rem; /* Adjust button size */
    bottom: -60%; /* Adjust button position */
  }

  .logo-image {
    height: 40px; /* Adjust logo size */
  }

  .header {
    font-size: 2em; /* Adjust header size */
  }

  .contact-form {
    max-width: 90%; /* Make form responsive */
  }

  .icon {
    padding: 20px; /* Adjust icon size */
  }

  .icon i {
    font-size: 24px; /* Adjust icon size */
  }
}
